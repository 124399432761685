import { Box, Group, Image, Loader, SimpleGrid, Stack, Text, Title } from '@mantine/core';
import { useQuery } from '@tanstack/react-query';
import { ErrorComponent, ErrorComponentProps, getRouteApi, useSearch } from '@tanstack/react-router';
import { dogParkLeaderboardOptions, LEADERBOARD_ENTRIES_LIMIT, LeaderboardData } from './DogParkLeaderboard.query';
import { AxiosError } from 'axios';
import { DogParkLeaderboardEntry } from '../DogParkLeaderboardEntry/DogParkLeaderboardEntry';
import { DogParkLeaderboardEmptyEntry } from '../DogParkLeaderboardEmptyEntry/DogParkLeaderboardEmptyEntry';
import { Carousel } from '@mantine/carousel';
import { useContext, useEffect, useRef, useState } from 'react';
import Autoplay from 'embla-carousel-autoplay';
import { QRCodeSVG } from 'qrcode.react';

import classes from './DogParkLeaderboard.module.css';

import FiLogo from '../../assets/fi_logo_yellow.svg';
import {
  DogParkScreenContext,
  DogParkScreenContextType,
  SECONDS_PER_PAGE_RELOAD,
} from '../DogParkScreen/DogParkScreenContext';
import { useIsConnected, useIsVideoReadyToPlay, useSecondsPassed } from '../../lib/helpers/leaderboard/utils';
import { DogParkLoader } from '../DogParkLoader/DogParkLoader';
import { LeaderboardType } from '../DogParkScreen/LeaderboardType';

const DISPLAY_LEADERBOARD_SECONDS = 30;
const LOAD_VIDEOS_SECONDS = 180; // 3 minutes

const route = getRouteApi('/dogpark/leaderboard/$id');

interface Testimonial {
  quote: string;
  attribution: string;
}

function getCodeUrl(qrCodeUrl?: string) {
  return qrCodeUrl ?? 'https://tryfi.com';
}

export function ConnectedDogParkLeaderboard() {
  const { id } = route.useParams();
  const fromDate = useSearch({ from: '/dogpark/leaderboard/$id' }).fromDate;

  const { error, data, isLoading } = useQuery(dogParkLeaderboardOptions(id, fromDate));

  if (isLoading) {
    return <Text>Loading...</Text>;
  }

  if (error) {
    return <Text>Error! {error.message}</Text>;
  }

  if (!data) {
    return <Text>Response data empty</Text>;
  }

  return <DogParkLeaderboard data={data} />;
}

export function DogParkLeaderboard({ data }: { data: LeaderboardData }) {
  const autoplay = useRef(Autoplay({ delay: 4000 }));

  // Attach context to leaderboard to enable dynamic behavior with custom content.
  const dogParkScreenContext = useContext(DogParkScreenContext);
  const { contentOnlyMode } = useDynamicModeLeaderboard(data, dogParkScreenContext);

  // Handle reloading window based component state *only*.
  // This will only run if the component has been continuously displayed for allotted time.
  useStaticModeHandleReloadWindow();

  // Control if the video sidebar (and children content) should be visible if the video is ready to play.
  const videoRef = useRef<HTMLVideoElement>(null);
  const { isVideoReadyToPlay } = useIsVideoReadyToPlay(videoRef.current, true);

  // break ties with step counts
  const sortedRanks = [...data.leaderboard].sort((a, b) =>
    b.visitCount === a.visitCount ? b.stepsThisMonth - a.stepsThisMonth : b.visitCount - a.visitCount,
  );

  const leaderboardEntries = sortedRanks.map((entry, index) => {
    return (
      <DogParkLeaderboardEntry
        entryData={entry}
        rank={index + 1}
        key={index}
        includeVisits={data.type === LeaderboardType.DOG_PARK}
      />
    );
  });

  // if the leaderboard is not full, add empty entries to fill it
  if (leaderboardEntries.length < LEADERBOARD_ENTRIES_LIMIT) {
    for (let i = leaderboardEntries.length; i < LEADERBOARD_ENTRIES_LIMIT; i++) {
      leaderboardEntries.push(<DogParkLeaderboardEmptyEntry rank={i + 1} key={i} />);
    }
  }

  const testimonials: Testimonial[] = [
    { quote: '“The peace of mind I get is worth every penny”', attribution: '@myheartbeatsatmyfeet' },
    { quote: '“I don’t worry about losing any of my six dogs now”', attribution: '@jeremygranthorton' },
    { quote: '“Helped me get my escape artist back!”', attribution: 'Rachel D.' },
    { quote: '“The best decision ever buying this GPS collar!!”', attribution: '@shirley2792' },
  ];

  const testimonialSlides = testimonials.map((t, i) => (
    <Carousel.Slide key={i}>
      <Stack className={classes.slide}>
        <Text className={classes.quoteText}>{t.quote}</Text>
        <Text className={classes.attributionText}>{t.attribution}</Text>
      </Stack>
    </Carousel.Slide>
  ));

  // Adjust content of the page to match the scale of the page
  // This reduces the vertical and horizontal padding around the content
  const scale = useScale();

  // Hide scroll bars when the page is scaled
  document.body.style.overflow = 'hidden';

  let titleFontSize = '48px';
  if (data.name.length > 45) {
    titleFontSize = '24px';
  } else if (data.name.length > 30) {
    titleFontSize = '36px';
  }

  if (contentOnlyMode) {
    // Hide leaderboard, load videos and iterate to next screen.
    return <DogParkLoader />;
  }

  return (
    <Stack justify="center" align="stretch" bg="background-dark-primary" h="100vh">
      <Group
        wrap="nowrap"
        justify="center"
        align="center"
        gap="40px"
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
        }}
        opacity={isVideoReadyToPlay ? 0 : 1}
      >
        <Loader color="fi-yellow" size={100} />
      </Group>
      <Group
        wrap="nowrap"
        justify="center"
        align="center"
        gap="40px"
        style={{
          transform: `scale(${scale})`,
        }}
        opacity={isVideoReadyToPlay ? 1 : 0}
      >
        <Stack className={classes.leaderboardContainer}>
          <Group wrap="nowrap" align="flex-start">
            <Image src={FiLogo} bg="fi-yellow" radius="sm" />
            <Title className={classes.leaderboardTitle} style={{ fontSize: titleFontSize }}>
              Top Dogs at {data.name}
            </Title>
          </Group>
          <Text className={classes.leaderboardSubtitle}>Get a Fi collar to join the chase!</Text>
          <SimpleGrid cols={2} spacing="16px">
            <Stack gap="16px">{leaderboardEntries.slice(0, 3)}</Stack>
            <Stack gap="16px">{leaderboardEntries.slice(3)}</Stack>
          </SimpleGrid>
        </Stack>
        <Stack align="center" className={classes.marketingSidebar}>
          <video autoPlay muted loop className={classes.videoBackground} ref={videoRef}>
            <source src={'/leaderboard_ranking_dog.mp4'} type="video/mp4" />
          </video>
          <Title className={classes.marketingTitle}>Meet Series 3 – Live GPS, health and behavior tracking</Title>
          <Box maw="100%" mt="auto">
            <Carousel
              loop
              draggable={false}
              withControls={false}
              slideSize="256px"
              slideGap="8px"
              plugins={[autoplay.current]}
            >
              {testimonialSlides}
            </Carousel>
          </Box>
          <Group className={classes.marketingFooter} wrap="nowrap">
            <QRCodeSVG size={80} value={getCodeUrl(data.qrCodeUrl)} marginSize={2} style={{ borderRadius: '6px' }} />
            <Stack gap="0px">
              <Text className={classes.promoText}>Visit tryfi.com</Text>
              <Text className={classes.promoText}>to learn more</Text>
            </Stack>
          </Group>
        </Stack>
      </Group>
    </Stack>
  );
}

export function DogParkLeaderboardError(props: ErrorComponentProps) {
  const { error } = props;
  if (error instanceof AxiosError) {
    return (
      <Box>
        <Title>Uh-oh!</Title>
        <Text>Something went wrong!</Text>
        <Text>Error: {error.message}</Text>
      </Box>
    );
  }
  return <ErrorComponent error={error} />;
}

function useScale() {
  const [scale, setScale] = useState(1);

  useEffect(() => {
    const handleResize = () => {
      const scaleFactor = Math.min(window.innerWidth / 1920, window.innerHeight / 1080);
      setScale(scaleFactor * 1.35);
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return scale;
}

// Enables dynamic leaderboard where content is displayed.
const useDynamicModeLeaderboard = (data: LeaderboardData, context?: DogParkScreenContextType) => {
  const isConnected = useIsConnected();

  const { isAllLoaded, timings } = context ?? {};
  const isTimePassed = useSecondsPassed(timings?.leaderboard ?? DISPLAY_LEADERBOARD_SECONDS);
  const contextReference = useRef(context);
  const { loadVideos, iterateScreenIndex, setDogParkName, dogParkQrCodeUrl, setDogParkQrCodeUrl } =
    contextReference.current ?? {};

  // Store dog park name for use in CTA screen.
  useEffect(() => {
    if (setDogParkName) {
      setDogParkName(data.name);
    }
  }, [setDogParkName, data]);

  useEffect(() => {
    if (setDogParkQrCodeUrl && data.qrCodeUrl) {
      setDogParkQrCodeUrl(data.qrCodeUrl);
    }
  }, [setDogParkQrCodeUrl, data.qrCodeUrl]);

  // Load videos
  useEffect(() => {
    if (loadVideos === undefined || !isConnected) {
      return;
    }

    loadVideos(timings?.video ?? LOAD_VIDEOS_SECONDS);
  }, [loadVideos, isConnected, timings?.video]);

  // If videos are loaded, iterate to the next screen for display.
  useEffect(() => {
    if (isAllLoaded === undefined || iterateScreenIndex === undefined || !isConnected) {
      return;
    }

    if (isAllLoaded && isTimePassed) {
      iterateScreenIndex();
    }
  }, [isAllLoaded, isConnected, isTimePassed, iterateScreenIndex]);

  return { contentOnlyMode: timings?.leaderboard === 0, dogParkQrCodeUrl };
};

const useStaticModeHandleReloadWindow = () => {
  const isTimeToReload = useSecondsPassed(SECONDS_PER_PAGE_RELOAD);

  useEffect(() => {
    if (isTimeToReload) {
      window.location.reload();
    }
  }, [isTimeToReload]);
};
