import { QRCodeSVG } from 'qrcode.react';
import { Image, Title, Text, Grid, Stack, Group, Flex } from '@mantine/core';
import FiLogo from '../../assets/fi_logo_yellow.svg';
import Masonry from 'react-masonry-css';
import classes from './DogParkJoinCTA.module.css';
import { useIsConnected, useSecondsPassed } from '../../lib/helpers/leaderboard/utils';
import { useEffect } from 'react';
import { useDogParkScreenContext } from '../DogParkScreen/useDogParkScreenContext';
import { DogParkLoader } from '../DogParkLoader/DogParkLoader';

const MASON_IMAGES = Array.from(
  { length: 9 },
  (_, i) => new URL(`../../assets/mason_dog_${i + 1}.png`, import.meta.url).href,
);

const DISPLAY_CTA_SECONDS = 30;

export const DogParkJoinCTA = () => {
  const { dogParkName, dogParkQrCodeUrl } = useDogParkScreenContext();
  const { contentOnlyMode } = useHandleNavigation();

  if (contentOnlyMode) {
    return <DogParkLoader />;
  }

  return (
    <Grid bg="black">
      <Grid.Col span={6} h="100vh" pl="5vh" display="flex">
        <Flex justify="center" direction="column">
          <Stack gap="5vh">
            <Image src={FiLogo} alt="Fi Logo" w="4vw" h="4vw" />
            <Title size="5.5vw" fw={1000} ta="left" c="white">
              Packs have more fun.
              <br />
              Join Fi today.
            </Title>
            <Group gap="1.5vw" wrap="nowrap">
              <QRCodeSVG size={80} value={dogParkQrCodeUrl} marginSize={2} style={{ borderRadius: '6px' }} />
              <Text size="1.5vw" fw={500} ta="left" c="white">
                Don't forget to ask {dogParkName} about special discounts for members.
              </Text>
            </Group>
          </Stack>
        </Flex>
      </Grid.Col>
      <Grid.Col span={6} className={classes.rightColumn}>
        <Masonry
          breakpointCols={{ default: 3 }}
          className={classes.masonryGrid}
          columnClassName={classes.masonryGridColumn}
        >
          {MASON_IMAGES.map((src, index) => (
            <Image key={index} src={src} alt={`Dog ${index}`} radius="1vw" mb="1vh" />
          ))}
        </Masonry>
      </Grid.Col>
    </Grid>
  );
};

const useHandleNavigation = () => {
  const { iterateScreenIndex, isTimeToReload, timings } = useDogParkScreenContext();
  const isConnected = useIsConnected();

  const isTimePassed = useSecondsPassed(timings.cta ?? DISPLAY_CTA_SECONDS);

  // After the time has passed, either loop around to the first screen (leaderboard) or reload the page
  // However, if not connected to the internet, just loop around to the first screen.
  useEffect(() => {
    if (isTimePassed) {
      if (isTimeToReload && isConnected) {
        window.location.reload();
      } else {
        iterateScreenIndex(0);
      }
    }
  }, [isTimePassed, iterateScreenIndex, isConnected, isTimeToReload]);

  return { contentOnlyMode: timings.cta === 0 };
};
