import { Group, Loader, Stack } from '@mantine/core';

export const DogParkLoader = () => {
  return (
    <Stack justify="center" align="center" bg="background-dark-primary" h="100vh">
      <Group wrap="nowrap" align="center" gap="40px">
        <Loader color="fi-yellow" size={100} />
      </Group>
    </Stack>
  );
};
