import { createFileRoute } from '@tanstack/react-router';
import {
  ConnectedDogParkLeaderboard,
  DogParkLeaderboardError,
} from '../components/DogParkLeaderboard/DogParkLeaderboard';
import { captureException } from '@sentry/react';
import { dogParkLeaderboardOptions } from '../components/DogParkLeaderboard/DogParkLeaderboard.query';
import { DogParkScreen } from '../components/DogParkScreen/DogParkScreen';
import { useSearch } from '@tanstack/react-router';
import { DogParkScreenContextProvider } from '../components/DogParkScreen/DogParkScreenContext';
import { LeaderboardType } from '../components/DogParkScreen/LeaderboardType';

const DogParkRouteComponent = () => {
  const search = useSearch({ from: '/dogpark/leaderboard/$id' });

  if (search === null || search === undefined) {
    return <ConnectedDogParkLeaderboard />;
  }

  const timings = {
    leaderboard: search.leaderboard,
    video: search.video,
    cta: search.cta,
  };

  if (search.dynamic !== 'true') {
    return <ConnectedDogParkLeaderboard />;
  }

  return (
    <DogParkScreenContextProvider timings={timings} type={LeaderboardType.DOG_PARK}>
      <DogParkScreen />
    </DogParkScreenContextProvider>
  );
};

export const Route = createFileRoute('/dogpark/leaderboard/$id')({
  component: DogParkRouteComponent,
  onError: ({ error }) => {
    captureException(error);
  },
  errorComponent: DogParkLeaderboardError,

  validateSearch: (search: string | Record<string, string> | string[][] | URLSearchParams | undefined) => {
    const params = new URLSearchParams(search as string);
    const fromDate = params.get('fromDate');
    const dynamic = params.get('dynamic');

    const leaderboard = params.get('leaderboard') === null ? 30 : Number(params.get('leaderboard'));
    const video = params.get('video') === null ? 180 : Number(params.get('video'));
    const cta = params.get('cta') === null ? 30 : Number(params.get('cta'));

    return { dynamic, fromDate, leaderboard, video, cta };
  },

  loader: ({ context: { queryClient }, params }) => {
    const searchParams = new URLSearchParams(window.location.search);
    const fromDate = searchParams.get('fromDate');

    return queryClient.ensureQueryData(dogParkLeaderboardOptions(params.id, fromDate));
  },
});
