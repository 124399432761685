import { Box, Text, Title } from '@mantine/core';
import { useQuery } from '@tanstack/react-query';
import { ErrorComponent, ErrorComponentProps, getRouteApi, useSearch } from '@tanstack/react-router';
import { packLeaderboardOptions } from './PackLeaderboard.query';
import { AxiosError } from 'axios';
import { DogParkLeaderboard } from '../DogParkLeaderboard/DogParkLeaderboard';

export function ConnectedPackLeaderboard() {
  const route = getRouteApi('/pack/leaderboard/$id');

  const { id } = route.useParams();
  const fromDate = useSearch({ from: '/pack/leaderboard/$id' }).fromDate;

  const { error, data, isLoading } = useQuery(packLeaderboardOptions(id, fromDate));

  if (isLoading) {
    return <Text>Loading...</Text>;
  }

  if (error) {
    return <Text>Error! {error.message}</Text>;
  }

  if (!data) {
    return <Text>Response data empty</Text>;
  }

  return <DogParkLeaderboard data={data} />;
}

export function PackLeaderboardError(props: ErrorComponentProps) {
  const { error } = props;
  if (error instanceof AxiosError) {
    return (
      <Box>
        <Title>Uh-oh!</Title>
        <Text>Something went wrong!</Text>
        <Text>Error: {error.message}</Text>
      </Box>
    );
  }
  return <ErrorComponent error={error} />;
}
