import { captureMessage } from '@sentry/browser';
import { queryOptions } from '@tanstack/react-query';
import axios from 'axios';
import { DateTime } from 'luxon';
import { LeaderboardType } from '../DogParkScreen/LeaderboardType';

export const LEADERBOARD_ENTRIES_LIMIT = 8;

export interface LeaderboardEntry {
  name: string;
  stepsThisMonth: number;
  age: string;
  breed?: string;
  visitCount: number;
  photoUrl?: string;
}

export interface LeaderboardData {
  name: string;
  qrCodeUrl?: string;
  leaderboard: LeaderboardEntry[];
  type: LeaderboardType;
}

const generatePackLeaderboardUrl = (id: string, fromDate: string | null) => {
  const webApiBaseUrl = import.meta.env.VITE_WEB_REST_API ?? 'http://localhost:8080/api';

  const params = new URLSearchParams();
  params.set('limit', LEADERBOARD_ENTRIES_LIMIT.toString());

  if (fromDate !== null) {
    const fromDateTime = DateTime.fromFormat(fromDate, 'MM-dd-yyyy');
    if (fromDateTime.isValid) {
      params.set('fromDate', fromDate);
    } else {
      captureMessage(`Invalid fromDate format in URL Leaderboard`, {
        level: 'warning',
        extra: { fromDate, leaderboardId: id, baseUrl: webApiBaseUrl },
      });
    }
  }

  const url = `${webApiBaseUrl}/packs/leaderboard/${id}?${params.toString()}`;
  return url;
};

export const packLeaderboardOptions = (id: string, fromDate: string | null, autoRefresh: boolean = true) =>
  queryOptions({
    queryKey: ['packId', { id }, 'fromDate', fromDate],
    queryFn: () => getPackLeaderboardData(id, fromDate),
    refetchInterval: autoRefresh ? 300_000 : false, // 5 minute refresh interval
  });

async function getPackLeaderboardData(id: string, fromDate: string | null): Promise<LeaderboardData> {
  const url = generatePackLeaderboardUrl(id, fromDate);

  const response = await axios.get<LeaderboardData>(url);

  const data = { ...response.data, type: LeaderboardType.PACK };
  return data;
}
