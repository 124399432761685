import React, { createContext, useCallback } from 'react';
import { useVideoLoader } from './useVideoLoader'; // Import the custom hook
import { useState } from 'react';
import { ConnectedDogParkLeaderboard } from '../DogParkLeaderboard/DogParkLeaderboard';
import DogParkVideo from '../DogParkVideo/DogParkVideo';
import { useSecondsPassed } from '../../lib/helpers/leaderboard/utils';
import { DogParkJoinCTA } from '../DogParkJoinCTA/DogParkJoinCTA';
import { ConnectedPackLeaderboard } from '../PackLeaderboard/PackLeaderboard';
import { LeaderboardType } from './LeaderboardType';
export interface DogParkScreenContextType {
  loadVideos: (videoSeconds: number) => Promise<void>;
  videoData: Record<string, any>;
  currentScreen: React.ReactNode;
  iterateScreenIndex: (indexOverride?: number) => void;
  isAllLoaded: boolean;
  isTimeToReload: boolean;
  dogParkName: string;
  setDogParkName: (dogParkName: string) => void;
  timings: DogParkScreenTimings;
  dogParkQrCodeUrl: string;
  setDogParkQrCodeUrl: (qrCodeUrl: string) => void;
}

export const SECONDS_PER_PAGE_RELOAD = 10 * 60; // 10 minutes

type DogParkScreenTimings = {
  leaderboard: number;
  video: number;
  cta: number;
};

interface IDogParkScreenContextProps {
  timings: DogParkScreenTimings;
  type: LeaderboardType;
}

export const DogParkScreenContext = createContext<DogParkScreenContextType | undefined>(undefined);

export const DogParkScreenContextProvider: React.FC<IDogParkScreenContextProps & { children: React.ReactNode }> = ({
  timings,
  type,
  children,
}) => {
  const [dogParkName, setDogParkName] = useState<string>('your dog park');
  const [dogParkQrCodeUrl, setDogParkQrCodeUrl] = useState<string>('https://tryfi.com');
  const { loadVideos, videoData, isAllLoaded } = useVideoLoader();

  const isTimeToReload = useSecondsPassed(SECONDS_PER_PAGE_RELOAD);

  const iterateScreenIndex = useCallback((indexOverride?: number) => {
    if (indexOverride !== undefined) {
      setCurrentScreenIndex(indexOverride);
    } else {
      setCurrentScreenIndex((prevIndex) => prevIndex + 1);
    }
  }, []);

  const [currentScreenIndex, setCurrentScreenIndex] = useState(0);

  const subScreens = [
    type === LeaderboardType.DOG_PARK ? <ConnectedDogParkLeaderboard /> : <ConnectedPackLeaderboard />,
    <DogParkVideo />,
    <DogParkJoinCTA />,
  ];
  const currentScreen = subScreens[currentScreenIndex];

  return (
    <DogParkScreenContext.Provider
      value={{
        loadVideos,
        videoData,
        currentScreen,
        iterateScreenIndex,
        isAllLoaded,
        isTimeToReload,
        dogParkName,
        setDogParkName,
        timings,
        dogParkQrCodeUrl,
        setDogParkQrCodeUrl,
      }}
    >
      {children}
    </DogParkScreenContext.Provider>
  );
};
