import { Avatar, Group, Image, Stack, Text } from '@mantine/core';
import classes from './DogParkLeaderboardEntry.module.css';

import DogIcon from '../../assets/dog_icon.svg';
import FireIcon from '../../assets/fire.svg';
import AwardFirst from '../../assets/award_first.svg';
import AwardSecond from '../../assets/award_second.svg';
import AwardThird from '../../assets/award_third.svg';
import AwardBlank from '../../assets/award_blank.svg';
import { LeaderboardEntry } from '../DogParkLeaderboard/DogParkLeaderboard.query';
import Lottie, { Options } from 'react-lottie';
import AnimatedCrownData from '../../assets/crown.json';
import { useEffect, useState } from 'react';

function VisitsBadge(props: { visitCount: number; isTopThree?: boolean }) {
  const { visitCount, isTopThree } = props;
  return (
    <Group mod={{ topthree: isTopThree }} className={classes.visitBadge} wrap="nowrap">
      <Image src={FireIcon} />
      <Text className={classes.visitText} truncate>
        {visitCount} {visitCount === 1 ? 'visit' : 'visits'}
      </Text>
    </Group>
  );
}

export function DogParkLeaderboardEntry(props: { entryData: LeaderboardEntry; rank: number; includeVisits?: boolean }) {
  const { entryData, rank, includeVisits } = props;

  const topThree = rank <= 3;

  return (
    <Group p="16px" gap={topThree ? '16px' : '12px'} wrap="nowrap" className={classes.box}>
      <Stack className={classes.avatarContainer} mod={{ topthree: topThree }}>
        {rank === 1 && <AnimatedCrown />}
        <Avatar src={entryData.photoUrl} radius="100px" size={topThree ? '88px' : '56px'}>
          <Image src={DogIcon} height={topThree ? '60px' : '30px'} />
        </Avatar>
        {topThree && includeVisits && <VisitsBadge visitCount={entryData.visitCount} isTopThree />}
      </Stack>
      <Stack className={classes.bio}>
        <Text mod={{ topthree: topThree }} truncate className={classes.bioNameText}>
          {entryData.name}
        </Text>
        <Text
          truncate
          className={classes.bioSecondaryText}
          size={entryData.breed && entryData.breed.length > 20 ? '12px' : '14px'}
        >
          {new Intl.NumberFormat().format(entryData.stepsThisMonth)} steps this month
        </Text>
        <Text
          truncate
          className={classes.bioSecondaryText}
          size={entryData.breed && entryData.breed.length > 20 ? '12px' : '14px'}
        >
          {entryData.age}
          {entryData.breed && `, ${entryData.breed}`}
        </Text>
      </Stack>
      {rank === 1 && <Image src={AwardFirst} />}
      {rank === 2 && <Image src={AwardSecond} />}
      {rank === 3 && <Image src={AwardThird} />}
      {!topThree && (
        <Group>
          {includeVisits && <VisitsBadge visitCount={entryData.visitCount} />}
          <Group pos="relative">
            <Image src={AwardBlank} />
            <Text className={classes.rankOverlay}>{rank}</Text>
          </Group>
        </Group>
      )}
    </Group>
  );
}

function AnimatedCrown() {
  const [play, setPlay] = useState(false);

  // Play the animation (2 seconds long) every 10 seconds
  useEffect(() => {
    const interval = setInterval(() => {
      setPlay(true);
      const timeout = setTimeout(() => setPlay(false), 2000);
      return () => clearTimeout(timeout);
    }, 10000);

    return () => clearInterval(interval);
  }, []);

  const defaultOptions: Options = {
    loop: false,
    autoplay: false,
    animationData: AnimatedCrownData,
  };

  return (
    <div className={classes.crown}>
      <Lottie options={{ ...defaultOptions }} isStopped={!play} />
    </div>
  );
}
