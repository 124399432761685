import './DogParkVideo.css';
import { useDogParkScreenContext } from '../DogParkScreen/useDogParkScreenContext';
import { RefObject, useEffect, useRef, useState } from 'react';
import { QRCodeSVG } from 'qrcode.react';
import FiLogo from '../../assets/fi_logo_yellow.svg';
import { Box, Loader, Text } from '@mantine/core';
import { useIsVideoReadyToPlay } from '../../lib/helpers/leaderboard/utils';
import { captureException } from '@sentry/react';

const VideoPlayer = () => {
  const { videoData, iterateScreenIndex, dogParkQrCodeUrl } = useDogParkScreenContext();

  const leftVideoRef = useRef<HTMLVideoElement>(null);
  const rightVideoRef = useRef<HTMLVideoElement>(null);

  const {
    leftCurrentVideoUrl,
    leftCurrentFiHandle,
    rightCurrentVideoUrl,
    rightCurrentFiHandle,
    showSide,
    handleVideoEnd,
  } = useVideoSequence(videoData, iterateScreenIndex, leftVideoRef, rightVideoRef);

  useEffect(() => {
    leftVideoRef.current?.play().catch((error) => {
      captureException(error, { extra: { videoUrl: leftCurrentVideoUrl, ordinal: 'first' } });
      iterateScreenIndex();
    });
    // Only play the first video on mount, iterateScreenIndex is stable, leftCurrentVideoUrl is not stable.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { isVideoReadyToPlay } = useIsVideoReadyToPlay(leftVideoRef.current, true);

  return (
    <div
      className="video-container"
      style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}
    >
      {isVideoReadyToPlay ? null : <Loader color="fi-yellow" size={100} />}
      <video
        className="video-foreground"
        src={leftCurrentVideoUrl}
        muted
        preload="auto"
        onEnded={async () => {
          await handleVideoEnd(VideoSide.LEFT);
        }}
        style={{ opacity: showSide === VideoSide.RIGHT || !isVideoReadyToPlay ? 0 : 1 }}
        ref={leftVideoRef}
      />
      <video
        className="video-foreground"
        src={rightCurrentVideoUrl}
        muted
        preload="auto"
        onEnded={async () => {
          await handleVideoEnd(VideoSide.RIGHT);
        }}
        style={{ opacity: showSide === VideoSide.LEFT || !isVideoReadyToPlay ? 0 : 1 }}
        ref={rightVideoRef}
      />
      <div className="video-overlay">
        <div className="qr-code">
          <QRCodeSVG size={80} value={dogParkQrCodeUrl} marginSize={2} style={{ borderRadius: '6px' }} />
        </div>
        <div className="fi-logo" style={{ transform: 'scale(1.5)' }}>
          <img src={FiLogo} alt="Fi Logo" />
        </div>
        <div className="user-handle">
          <Box>
            <Text c="black">@{showSide === VideoSide.LEFT ? leftCurrentFiHandle : rightCurrentFiHandle}</Text>
          </Box>
        </div>
      </div>
    </div>
  );
};

enum VideoSide {
  LEFT = 'LEFT',
  RIGHT = 'RIGHT',
}

const useVideoSequence = (
  videoData: Record<string, any>,
  onComplete: () => void,
  leftSideVideoRef: RefObject<HTMLVideoElement>,
  rightSideVideoRef: RefObject<HTMLVideoElement>,
) => {
  const videoIds = Object.keys(videoData);
  const [leftCurrentVideoIndex, setLeftCurrentVideoIndex] = useState(0);
  const [rightCurrentVideoIndex, setRightCurrentVideoIndex] = useState(1);
  const [showSide, setShowSide] = useState<VideoSide>(VideoSide.LEFT);

  const leftCurrentVideoId = videoIds[leftCurrentVideoIndex];
  const leftCurrentVideoUrl = videoData[leftCurrentVideoId].videoObjectUrl;
  const leftCurrentFiHandle = videoData[leftCurrentVideoId].fiHandle;

  const rightCurrentVideoId = videoIds[rightCurrentVideoIndex];
  const rightCurrentVideoUrl = videoData[rightCurrentVideoId].videoObjectUrl;
  const rightCurrentFiHandle = videoData[rightCurrentVideoId].fiHandle;

  const handleVideoEnd = async (videoSide: VideoSide) => {
    const isLeftSide = videoSide === VideoSide.LEFT;
    const currentVideoIndex = isLeftSide ? leftCurrentVideoIndex : rightCurrentVideoIndex;
    const nextVideoIndex = currentVideoIndex + 2;

    if (nextVideoIndex === videoIds.length) {
      onComplete();
      return;
    }

    try {
      if (isLeftSide) {
        setLeftCurrentVideoIndex(nextVideoIndex);
        leftSideVideoRef.current?.pause();
        await rightSideVideoRef.current?.play();
        setShowSide(VideoSide.RIGHT);
      } else {
        setRightCurrentVideoIndex(nextVideoIndex);
        rightSideVideoRef.current?.pause();
        await leftSideVideoRef.current?.play();
        setShowSide(VideoSide.LEFT);
      }
    } catch (error) {
      // Any errors on video control will be logged, then we transition to
      // CTA screen early.
      captureException(error);
      onComplete();
    }
  };

  return {
    leftCurrentVideoUrl,
    leftCurrentFiHandle,
    rightCurrentVideoUrl,
    rightCurrentFiHandle,
    showSide,
    handleVideoEnd,
  };
};

export default VideoPlayer;
